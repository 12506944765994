export const Tech=()=>{
    return  <section className="about section" id="tech">
             <div className="container">
                  <div className="row">
    
                          <div className="mt-lg-5 mb-lg-0 mb-0 col-lg-5 col-md-10 mx-auto col-12">
                              <h2 className="mb-4" data-aos="fade-up" data-aos-delay="300"> 我们的技术</h2>
                              <ul>
                                <li data-aos="fade-up" data-aos-delay="400">
                                    <strong>文档分析：</strong>
                                    借助先进的AI技术，我们的文档分析服务能迅速理解和处理复杂数据，提供深度洞察，助力企业做出更明智的决策。
                                </li>
                                <li data-aos="fade-up" data-aos-delay="500">
                                    <strong>图片生成：</strong>
                                    创新的AI图片生成工具，将您的想象力转化为视觉现实，为艺术创作和商业设计提供无限可能。
                                </li>
                            </ul>
                          </div>
    
                          <div className="mt-lg-5 mb-lg-0 mb-4 col-lg-5 col-md-10 mx-auto col-12" data-aos="fade-up" data-aos-delay="700">
                            <ul>
                                <li data-aos="fade-up" data-aos-delay="300">
                                    <strong>语音对话：</strong>
                                    通过我们的AI语音对话系统，享受流畅自然的交流体验，无论是客户服务还是日常沟通，都能更加高效和亲切。
                                </li>
                                <li data-aos="fade-up" data-aos-delay="400">
                                    <strong>企业流程智能化：</strong>
                                    智能化企业流程管理，运用尖端AI技术优化工作流程，提高效率，减少错误，让业务运行更加顺畅。
                                </li>
                                <li data-aos="fade-up" data-aos-delay="500">
                                    <strong>自动Agent：</strong>
                                    自动Agent，提供24/7的智能服务，从数据分析到客户互动，每一步都精准高效，赋能企业走向未来。
                                </li>
                            </ul>
                          </div>
                  </div>
             </div>
    </section>
}