import logo from "../images/logo.png";
export const Navigation = ()=>{
    return <nav className="navbar navbar-expand-lg fixed-top">
    <div className="container">
    <div  className="header-logo"><img src={logo} alt=""/></div>

        <a className="navbar-brand" href="#home">吉澳科技</a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-lg-auto">             
                <li className="nav-item">
                    <a href="#home" className="nav-link smoothScroll">关于我们</a>
                </li>
                <li className="nav-item">
                    <a href="#tech" className="nav-link smoothScroll">技术研究</a>
                </li>
                <li className="nav-item">
                    <a href="#products" className="nav-link smoothScroll">产品展示</a>
                </li>
                <li className="nav-item">
                    <a href="#footer" className="nav-link smoothScroll">联系我们</a>
                </li>
            </ul>

            {/* <ul className="social-icon ml-lg-3">
                <li><a href="https://fb.com/tooplate" className="fa fa-weixin"></a></li>
                <li><a href="#" className="fa fa-phone"></a></li>
                <li><a href="#" className="fa fa-envelope"></a></li>
            </ul> */}
        </div>

    </div>
</nav>
}