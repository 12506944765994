/* eslint-disable jsx-a11y/anchor-is-valid */
export const Intro = ()=>{
    return <section className="feature" id="intro">
    <div className="container">
        <div className="row">
  
            <div className="d-flex flex-column justify-content-center ml-lg-auto mr-lg-5 col-lg-5 col-md-6 col-12">
                <h2 className="mb-3 text-white" data-aos="fade-up">公司简介</h2>
  
                <h6 className="mb-4 text-white" data-aos="fade-up">我们是做什么的?</h6>
  
                <p data-aos="fade-up" data-aos-delay="200">深圳吉澳科技有限公司是一家初创企业,依托人工智能技术，以及开放的技术资源平台的结合，充分利用AI的特性,挖掘AI的潜力项目,服务于多个互联网场景，重塑传统行业的构造，赋予社会生态新的活力！</p>
  
                <a href="#"  className="btn custom-btn bg-color mt-3" data-aos="fade-up" data-aos-delay="300" data-toggle="modal" data-target="#membershipForm">人工智能项目合作洽谈</a>
            </div>
  
            <div className="mr-lg-auto mt-3 col-lg-4 col-md-6 col-12">
                 <div className="about-working-hours">
                      <div>
  
                            <h2 className="mb-4 text-white" data-aos="fade-up" data-aos-delay="500">公司核心价值观</h2>
  
                           <strong className="d-block" data-aos="fade-up" data-aos-delay="600">提升社会生产力和消费力，释放潜力</strong>
  
                           <strong className="mt-3 d-block" data-aos="fade-up" data-aos-delay="700">我们的工作理念</strong>
  
                            <p data-aos="fade-up" data-aos-delay="800">简单，务实，高效，专注</p>
  
                            <strong className="mt-3 d-block" data-aos="fade-up" data-aos-delay="700">我们的目标是</strong>
  
                            <p data-aos="fade-up" data-aos-delay="800">让AI更好的服务人类</p>
                           </div>
                      </div>
                 </div>
            </div>  
        </div>
    </section>
}