import hero from "../images/hero-bg1.png";
export const Hero = () => {
  return (
    <section
      className="hero d-flex flex-column justify-content-center align-items-center"
      style={{backgroundImage: `url(${hero})`}}
      id="home"
    >
      <div className="bg-overlay">
        {/* <!-- <video id="myVideo"  autoplay loop  controls className="hero-video" > -->
<!-- <source src="videos/hero-brain.mp4" type="video/mp4"> -->
<!-- <source src="videos/hero-brain.ogg" type="video/ogg"> -->
<!-- Your browser does not support the video tag. -->
<!-- </video> --> */}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto col-12">
            <div className="hero-text mt-5 text-center">
              <h6 data-aos="fade-up" data-aos-delay="300">
              吉澳科技
              </h6>

              <h1 className="text-white" data-aos="fade-up" data-aos-delay="500">
              小巧智能，大有可为
              </h1>

              <a
                href="#intro"
                className="btn custom-btn mt-3"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                公司简介
              </a>

              <a
                href="#tech"
                className="btn custom-btn bordered mt-3"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                更多
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
