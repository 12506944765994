import './App.css';
import {Navigation} from './libs/navigation.js';
import { Hero } from './libs/hero.js';
import { Intro } from './libs/intro.js';
import { Tech } from './libs/tech.js';
import { Products } from './libs/products.js';
import { Banner } from './libs/banner.js';
function App(){
  return (
    <div className="App">
      <Navigation></Navigation>
      <Hero></Hero>
      <Intro></Intro>
      <Tech></Tech>
      <Products></Products>
      <Banner></Banner>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
