import banner from "../images/techbanner.png"
export const Banner = ()=>{
    return <>
    <section className="schedule section" id="techbanner" style={{backgroundImage: `url(${banner})`,
    backgroundSize: "initial",minHeight: "500px",    backgroundPositionY: "top",
    backgroundPositionX: "center"}}>
             <div className="container">
                  <div className="row">
    
                          <div className="col-lg-12 col-12 text-center">
                              <h6 data-aos="fade-up">自动完善，智造未来</h6>
  
                              <h2 className="text-white" data-aos="fade-up" data-aos-delay="200">一切交给机器</h2>
                           </div>
    
                  </div>
             </div>
    </section></>
}