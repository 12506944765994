import paipaiwen from "../images/products/paipaiwen256.png";
import designer from "../images/products/designer256.png";
import herodev from "../images/products/herodev256.png";
export const Products = ()=>{
    return <section className="class section" id="products">
    <div className="container">
         <div className="row">

                 <div className="col-lg-12 col-12 text-center mb-5">
                     <h6 data-aos="fade-up">将科幻变为现实</h6>

                     <h2 data-aos="fade-up" data-aos-delay="200">我们的AI产品系列</h2>
                  </div>

                 <div className="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-delay="400">
                     <div className="class-thumb">
                         <img src={paipaiwen} className="img-fluid" alt="Class" />

                         <div className="class-info">
                             <h3 className="mb-1">拍拍问</h3>

                             <span><strong>智能文档助手</strong></span>

                             {/* <span className="class-price">开发中</span> */}

                             <p className="mt-3">
                                {/* 快速且准确地扫描和分析各种文档。 */}
                                只需一次简单的拍照，我们的智能系统就能即时解读文本内容，提供精准的答案和洞察</p>
                         </div>
                     </div>
                 </div>

                 <div className="mt-5 mt-lg-0 mt-md-0 col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-delay="500">
                     <div className="class-thumb">
                         <img src={designer} className="img-fluid" alt="Class" />

                         <div className="class-info">
                             <h3 className="mb-1">设计奇兵</h3>

                             <span><strong>将草图变为杰作</strong></span>

                             {/* <span class="class-price">$66</span> */}

                             <p className="mt-3">我们的智能系统理解您的创意愿景，即刻将初步的想法转化为令人惊叹的视觉效果</p>
                         </div>
                     </div>
                 </div>

                 <div className="mt-5 mt-lg-0 col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-delay="600">
                     <div className="class-thumb">
                         <img src={herodev} className="img-fluid" alt="Class" />

                         <div className="class-info">
                             <h3 className="mb-1">独孤开发</h3>

                             <span><strong>智能开发助手</strong></span>

                             {/* <span class="class-price">$75</span> */}

                             <p className="mt-3">根据具体需求，高效、智能地完成任务。它就像您身边的英雄，随时准备帮助您解决复杂的编程挑战</p>
                         </div>
                     </div>
                 </div>

         </div>
    </div>
</section>

}